export enum NetworkIdEnum {
  AmoyTestnetNetworkId = 80_002,
  MaticMainnetNetworkId = 137,
  BaseMainnetNetworkId = 8453,
  BaseSepoliaNetworkId = 84532,
}

export const networkNames = {
  [NetworkIdEnum.AmoyTestnetNetworkId]: 'Amoy Testnet',
  [NetworkIdEnum.MaticMainnetNetworkId]: 'Matic Mainnet',
  [NetworkIdEnum.BaseMainnetNetworkId]: 'Base Mainnet',
  [NetworkIdEnum.BaseSepoliaNetworkId]: 'Base Sepolia',
};

export const networkUrls = {
  [NetworkIdEnum.AmoyTestnetNetworkId]: 'https://amoy.polygonscan.com/',
  [NetworkIdEnum.MaticMainnetNetworkId]: 'https://polygonscan.com/',
  [NetworkIdEnum.BaseMainnetNetworkId]: 'https://basescan.org/',
  [NetworkIdEnum.BaseSepoliaNetworkId]: 'https://sepolia.basescan.org/',
};
