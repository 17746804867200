import {
  AuctionInterface,
  AuctionStatus,
  AvailabilityStatus,
  CheckUserHasNftEnum,
  NftSellingType,
  NftType,
} from '@cere/services-types';
import {useCallback, useMemo} from 'react';
import {Trans, useTranslation} from 'react-i18next';

import transferring from '../../../assets/svg/sand-clock.svg';
import {nftSoldOut} from '../../../utils/helpers/nft-availability';
import {Badge} from '../../shared/Badge';
import {CardHorizontal, CardHorizontalProps} from '../../shared/CardHorizontal';
import {NftCardCompositeButton} from '../ActionButton/NftCardCompositeButton';

export type ConnectedCardHorizontalViewProps = {
  nftId: string;
  nftOrderId: string;
  hasPendingTransaction?: boolean;
  nftPurchaseStatus?: CheckUserHasNftEnum;
  nftAuction?: AuctionInterface;
  nftAvailability: AvailabilityStatus;
  nftSellingType?: NftSellingType;
  nftType: NftType;
  nftBalance?: number;
  nftSupply?: number;
  nftPrice: number;
  priceUsd: number;
  cardLink: string;
  minterWalletAddress: string;
  sellerWalletAddress?: string;
  auctionStatus: AuctionStatus;
  userWalletAddress: string | null;
  isNftPending?: boolean;
  purchaseFlow?: boolean;
  ownedBalance?: string;
  callback?: () => void;
} & Omit<CardHorizontalProps, 'subTitle' | 'priceUSD' | 'renderActionElement'>;

export const ConnectedCardHorizontalView = ({
  nftId,
  nftOrderId,
  hasPendingTransaction,
  nftPurchaseStatus,
  nftSellingType,
  nftType,
  nftBalance,
  nftSupply,
  minterWalletAddress,
  sellerWalletAddress,
  cardLink,
  auctionStatus,
  userWalletAddress,
  nftAvailability,
  nftAuction,
  purchaseFlow,
  ownedBalance,
  isNftPending,
  callback,
}: ConnectedCardHorizontalViewProps) => {
  const {t} = useTranslation();

  const hidePrice = useCallback(() => {
    let ret = false;
    if (purchaseFlow) {
      return ret;
    }
    if (
      nftSoldOut({
        availability: nftAvailability!,
        auction: nftAuction,
        auctionStatus: auctionStatus,
        balance: nftBalance!,
      }) ||
      nftAvailability === AvailabilityStatus.COMING_SOON ||
      (auctionStatus === AuctionStatus.NOT_STARTED && nftType !== NftType.ACCESS)
    ) {
      ret = true;
    }
    return ret;
  }, [purchaseFlow, nftAvailability, nftAuction, auctionStatus, nftBalance, nftType]);

  const subTitle = useMemo(
    () => (
      <div className="flex flex-col md:flex-row items-start md:items-center">
        <div>
          {auctionStatus === AuctionStatus.ACTIVE ? (
            t('1 Auctioned Original')
          ) : (
            <Trans
              i18nKey={
                purchaseFlow
                  ? '<strong>{{leftAmount}}</strong>/{{totalAmount}} left'
                  : '<strong>{{leftAmount}}</strong>/{{totalAmount}} collectibles left'
              }
              values={{
                leftAmount: nftBalance,
                totalAmount: nftSupply,
              }}
              count={nftBalance}
            />
          )}
        </div>
        <div className="mb-2 md:mb-0 md:ml-2">
          {isNftPending ? (
            <Badge
              classes={{
                badgeText: '!text-sm !font-normal !text-[#000000]',
                badgeBox: 'align-center !bg-[#C9C9C94D]',
              }}
              text={t('Transferring')}
              icon={transferring}
            />
          ) : (
            ownedBalance &&
            Number(ownedBalance) > 0 && (
              <Badge
                classes={{
                  badgeText: '!text-[12px] !font-normal !text-[#000000]',
                  badgeBox: 'align-center !bg-[#C9C9C94D]',
                }}
                text={`${t('Collected')}: ${ownedBalance}`}
              />
            )
          )}
        </div>
      </div>
    ),
    [auctionStatus, isNftPending, nftBalance, nftSupply, ownedBalance, purchaseFlow, t],
  );

  const renderCardButton = useCallback(
    () => (
      <NftCardCompositeButton
        nftId={nftId}
        nftOrderId={nftOrderId}
        nftPurchaseStatus={nftPurchaseStatus}
        nftSellingType={nftSellingType}
        nftType={nftType}
        sellerWalletAddress={sellerWalletAddress || minterWalletAddress}
        disabled={hasPendingTransaction}
        cardLink={cardLink}
        userWalletAddress={userWalletAddress}
        purchaseFlow={purchaseFlow}
        buttonSize={purchaseFlow ? 'small' : undefined}
        callback={callback}
      />
    ),
    [
      nftId,
      nftOrderId,
      nftPurchaseStatus,
      nftSellingType,
      nftType,
      sellerWalletAddress,
      minterWalletAddress,
      hasPendingTransaction,
      cardLink,
      userWalletAddress,
      purchaseFlow,
      callback,
    ],
  );

  return (
    <CardHorizontal
      nftId={nftId}
      subTitle={subTitle}
      renderActionElement={renderCardButton}
      hidePrice={hidePrice()}
      purchaseFlow={purchaseFlow}
    />
  );
};
