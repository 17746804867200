import {gql} from '@apollo/client';

import {UPLOAD_FILE_FRAGMENT} from './fragments';

export const ARTIST_PROFILE_FRAGMENT = gql`
  ${UPLOAD_FILE_FRAGMENT}
  fragment ArtistProfile on CmsV2Creator {
    id
    name
    about
    instagramFollowerNumber
    facebookFollowerNumber
    twitterFollowerNumber
    youtubeFollowerNumber
    tiktokFollowerNumber
    discordLink
    desktopBackgroundImage {
      ...Media
    }
    tabletBackgroundImage {
      ...Media
    }
    mobileBackgroundImage {
      ...Media
    }
    avatar {
      ...Media
    }
    seoTitle
    seoDescription
    seoCanonical
    seoImage {
      ...Media
    }
    locale
    localizations {
      id
      locale
    }
  }
`;
