const parse = (value: string | null) => {
  if (!value) {
    return '';
  }

  try {
    return JSON.parse(value);
  } catch (e) {
    console.error(e);
  }
};

export const getStorageItem = (key: string) => parse(localStorage.getItem(key));
