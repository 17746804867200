import {Box, makeStyles, Theme, Typography} from '@material-ui/core';
import {generatePath, Link} from 'react-router-dom';

import {ReactComponent as ArrowRightIcon} from '../../../assets/svg/arrow-right.svg';
import {ReactComponent as LockClosedIcon} from '../../../assets/svg/lock-closed.svg';
import {ROUTES} from '../../../constants/routes';
import {useLocalization} from '../../../hooks/use-locale.hook';
import {UsersNftCardInterface} from '../../../types/nft';
import {ConnectedEventUnlockProgress} from '../../connected/EventUnlockProgress';
import {Badge} from '../Badge';

export const useStyles = makeStyles<Theme, {purchaseFlow: boolean; isUserAuthenticated: boolean}>((theme) => ({
  block: {
    background: theme.palette.background.paper,
    padding: ({purchaseFlow}) => (purchaseFlow ? '8px 12px' : '12px'),
    borderRadius: ({purchaseFlow}) => (purchaseFlow ? '12px' : theme.shape.borderRadius),
    '&:not(:last-child)': {
      marginBottom: '12px',
    },
    border: ({purchaseFlow, isUserAuthenticated}) =>
      purchaseFlow ? (!isUserAuthenticated ? '1px solid #49BF63' : '1px solid #E0E0E7') : 'none',
  },
  img: {
    maxWidth: '100%',
    width: ({purchaseFlow}) => (purchaseFlow ? '60px' : '68px'),
    height: ({purchaseFlow}) => (purchaseFlow ? '80px' : '90px'),
    borderRadius: '4px',
    marginRight: ({purchaseFlow}) => (purchaseFlow ? '0' : '12px'),
    objectFit: 'cover',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  gridRow: {
    display: 'grid',
    gridTemplateColumns: ({purchaseFlow}) => (purchaseFlow ? '60px auto' : '68px auto 16px'),
    columnGap: '12px',
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  flexItemsCenter: {
    alignItems: 'center',
  },
  lockIcon: {
    width: '15px',
    height: '14px',
    marginRight: '4px',
  },
  title: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '22px',
    flex: 1,
  },
  purchasedNft: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    padding: '4px',
    borderRadius: '4px',
    display: 'grid',
    gridTemplateColumns: '24px auto',
    columnGap: '4px',

    '& > div > img': {
      width: '24px',
      height: '24px',
      borderRadius: '4px',
    },
  },
  nftTitle: {
    fontWeight: 700,
    lineHeight: '5px',
  },
}));

type Props = {
  title: string;
  image: string;
  id: string | number;
  slug: string;
  isEventLocked: boolean;
  nft?: UsersNftCardInterface;
  analyticEventId?: string;
  purchaseFlow?: boolean;
  isUserAuthenticated?: boolean;
};
export const EventCardSmall = ({
  title,
  image,
  slug,
  isEventLocked,
  analyticEventId,
  nft,
  purchaseFlow = false,
  isUserAuthenticated = false,
}: Props) => {
  const {locale, t} = useLocalization();
  const classes = useStyles({purchaseFlow, isUserAuthenticated});
  return (
    <Box className={`${classes.block} ${classes.flexCol}`}>
      <Box className={`${classes.gridRow} ${classes.flexItemsCenter}`}>
        <Box className={classes.imgContainer}>
          <Link className={analyticEventId} to={`/${locale}/home/event/${slug}`}>
            <img className={classes.img} src={image} alt="" />
          </Link>
        </Box>
        <Box className={`${classes.flexCol}`}>
          <Box className={`${classes.flexRow} ${classes.flexItemsCenter}`}>
            {isEventLocked && <LockClosedIcon className={classes.lockIcon} />}
            <Link to={`/${locale}/home/event/${slug}`}>
              <Typography className={classes.title}>{title}</Typography>
            </Link>
          </Box>
          {!purchaseFlow && <ConnectedEventUnlockProgress eventSlug={slug} smallEventCard />}
          {!purchaseFlow && (
            <Link className="block" to={`/${locale}/home/event/${slug}`}>
              <ArrowRightIcon />
            </Link>
          )}
          {purchaseFlow && nft && (
            <>
              <Box className={classes.purchasedNft}>
                <Link to={generatePath(ROUTES.NFT_PAGE, {nftId: nft.id, locale})}>
                  <img className="w-6 h-6" src={nft.image} alt="" />
                </Link>
                <Box flex flexDirection="column">
                  <Typography variant="h6" className={classes.nftTitle}>
                    {nft.title}
                  </Typography>
                  <Badge
                    classes={{
                      badgeText: '!text-[8px] uppercase !text-[#3CBCAC] !font-bold',
                      badgeBox: 'align-center !bg-[#3CBCAC1A] !px-[3px] !py-[0px]',
                      icon: 'w-[8px] h-auto',
                    }}
                    text={t('Collected')}
                  />
                </Box>
              </Box>
              <Typography variant="caption" color="textSecondary">
                {t('Now you can attend this event')}
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
