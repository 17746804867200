export enum GoogleAnalyticsId {
  SubscribeBtn = 'subscribe-btn',
  ViewCreatorBtn = 'view-creator-btn',
  ConnectWalletBtn = 'connect-wallet-btn',
  CollectibleBuyBtn = 'collectible-buy-btn',
  UnlockExperienceBtn = 'unlock-experience-btn',
  UnlockEventBtn = 'unlock-event-btn',
  GenerateTicketBtn = 'generate-ticket-btn',
  AddEventToCalendarBtn = 'add-event-to-calendar-btn',
}
