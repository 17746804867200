import {v4 as uuidv4} from 'uuid';

import {IDENTITY_API_URL} from '../../config/common';
import {post} from '../lib/request';
import {LocalStorageKeys} from '../types/local-storage-keys';
import {UtmData} from '../types/utm';
import {CereProvider} from './analytic-providers/cere-provider';
import {FacebookProvider} from './analytic-providers/facebook-provider';
import {GTMProvider} from './analytic-providers/gtm-provider';
import {ProviderInterface, User} from './analytic-providers/provider-interface';
import {SegmentProvider} from './analytic-providers/segment-provider';

export enum AnalyticEventsEnum {
  INTERSTITIAL_SUBSCRIBE_EMAIL_FOCUSED = 'INTERSTITIAL_SUBSCRIBE_EMAIL_FOCUSED',
  INTERSTITIAL_SUBSCRIBE_SUBMIT = 'INTERSTITIAL_SUBSCRIBE_SUBMIT',

  SIGN_IN_OTP_SUBMITTED = 'SIGN_IN_OTP_SUBMITTED',

  USER_AUTHENTICATED = 'USER_AUTHENTICATED',
  USER_AUTHENTICATED_WEB = 'USER_AUTHENTICATED_WEB',
  USER_AUTHENTICATED_RN = 'USER_AUTHENTICATED_RN',

  //Concert page
  COLLECTIBLE_PURCHASE = 'COLLECTIBLE_PURCHASE',
  SIGN_IN_BUY_COLLECTIBLE = 'SIGN_IN_BUY_COLLECTIBLE',
  STAY_UPDATED_SUBSCRIPTION = 'STAY_UPDATED_SUBSCRIPTION',
  EMAIL_SUBMITTED = 'EMAIL_SUBMITTED',
}

export class AnalyticService {
  private readonly providers: ProviderInterface[];
  private readonly gtmProvider: ProviderInterface;

  constructor() {
    let sessionId = window.localStorage.getItem(LocalStorageKeys.SESSION_ID);
    if (!sessionId) {
      sessionId = uuidv4();
      window.localStorage.setItem(LocalStorageKeys.SESSION_ID, sessionId);
    }
    this.providers = [new CereProvider(sessionId), new SegmentProvider(sessionId), new FacebookProvider()];
    this.gtmProvider = new GTMProvider();
    this.init();
  }

  identify(user: User, metadata?: Record<string, string | number>) {
    this.providers.forEach((provider) => provider.identify(user, metadata));
  }

  init() {
    this.providers.forEach((provider) => provider.init());
  }

  track(event?: string, payload: Record<string, string | number | boolean | null> = {}) {
    if (event) {
      this.providers.forEach((provider) => provider.track(event, payload));
    }
  }

  gtmTrack(event?: string, payload: Record<string, string | number | boolean | null> = {}) {
    if (event) {
      this.gtmProvider.track(event, payload);
    }
  }
}

export const saveUtmData = async (jwtToken: string, utmData: UtmData): Promise<void> => {
  try {
    await post(
      `/analytics/save-utm-data`,
      {...utmData},
      {
        base: IDENTITY_API_URL(),
        headers: {Authorization: `Bearer ${jwtToken}`},
      },
    );
  } catch (error) {
    console.error('Failed to send utm data.', error.message);
  }
};

const analyticService = new AnalyticService();
export default analyticService;
