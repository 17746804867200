import {Button, Typography} from '@cere/rxb-template-ui-kit';
import {Box} from '@material-ui/core';
import {FC} from 'react';

import {ReactComponent as CheckIcon} from '../../../../assets/svg/check.svg';
import {useLocalization} from '../../../../hooks/use-locale.hook';

export interface AccessNftInfoModalProps {
  handleButtonClick: Function;
  accessTokenType?: boolean;
  appTitle: string;
}

export const AccessNftInfoModal: FC<AccessNftInfoModalProps> = ({
  handleButtonClick,
  accessTokenType = true,
  appTitle,
}) => {
  const {t} = useLocalization();

  const textModal = {
    accessInfo: [
      t(
        'This digital collectible will also serve as the entrance ticket to your event! You can view the QR code after purchase in your “Collectors Profile”.',
      ),
      t(
        `{{appTitle}} Digital Collectibles are the keys that unlock great content and experiences from your favorite artists!`,
        {
          appTitle: appTitle,
        },
      ),
      t(
        'You can access any available content associated with this collectible by going to the collectible page and using the inbuilt media player.',
      ),
      t('Digital Collectibles can be bought and sold on the {{appTitle}} Marketplace, which is launching soon.', {
        appTitle: appTitle,
      }),
    ],
    exclusiveInfo: [
      t('Unique collectibles which can only be purchased as NFT ticket holder'),
      t('Place your bid on auction NFTs to own the one-and-only copy of the creator’s original asset'),
      t('Purchase limited edition NFTs of high resolution assets from the creator'),
    ],
  };

  return (
    <Box display="flex" flexDirection="column">
      {accessTokenType && (
        <Box m="0 0 6px 0" display="flex">
          <Box mr="12px">
            <CheckIcon />
          </Box>
          <Typography variant="body2">{textModal.accessInfo[0]}</Typography>
        </Box>
      )}
      <Box m="0 0 6px 0" display="flex">
        <Box mr="12px">
          <CheckIcon />
        </Box>
        <Typography variant="body2">
          {accessTokenType ? textModal.accessInfo[1] : textModal.exclusiveInfo[0]}
        </Typography>
      </Box>
      <Box m="6px 0" display="flex">
        <Box mr="12px">
          <CheckIcon />
        </Box>
        <Typography variant="body2">
          {accessTokenType ? textModal.accessInfo[2] : textModal.exclusiveInfo[1]}
        </Typography>
      </Box>
      <Box m="6px 0" display="flex">
        <Box mr="12px">
          <CheckIcon />
        </Box>
        <Typography variant="body2">
          {accessTokenType ? textModal.accessInfo[3] : textModal.exclusiveInfo[2]}
        </Typography>
      </Box>
      <Box m="18px auto 0" width="100%" maxWidth="140px">
        <Button color="primary" variant="contained" size="large" fullWidth onClick={handleButtonClick}>
          {t('Got it')}
        </Button>
      </Box>
    </Box>
  );
};
