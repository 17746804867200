import {connect} from 'react-redux';
import {Dispatch} from 'redux';

import {getWalletDetails} from '../../components/connected/ConnectedUserMenu';
import {SupportedWalletType} from '../../models/wallet/types';
import {selectAppConfig} from '../../redux/modules/app-config/selectors';
import {FetchProfileCommand} from '../../redux/modules/collectors/actions';
import {
  selectCollectorsProfile,
  selectMyCollectorsProfile,
  selectMyCollectorsProfileLoadingState,
} from '../../redux/modules/collectors/selectors';
import {FetchCreatorsCommand} from '../../redux/modules/creators';
import {selectCreatorsLoading, selectRandomCreator} from '../../redux/modules/creators/selectors';
import {LoadNftsArrayByIdsCommand} from '../../redux/modules/nfts/actions';
import {DisconnectWalletCommand, OpenWallet} from '../../redux/modules/wallets';
import {
  selectActiveWalletType,
  selectConnectedWalletTypes,
  selectWalletAddress,
  selectWalletBalance,
} from '../../redux/modules/wallets/selectors';
import {WalletPageView} from './view';

const mapStateToProps = (state: any) => {
  const activeWalletType = selectActiveWalletType(state);
  const walletPublicKey = selectWalletAddress(state, activeWalletType)?.toLowerCase();

  const profileNfts = selectCollectorsProfile(state, walletPublicKey!)?.collectedNfts;
  const loading = selectMyCollectorsProfileLoadingState(state);
  const appConfig = selectAppConfig(state);

  const isCreatorsLoading = selectCreatorsLoading(state);
  const randomCreator = selectRandomCreator(state);

  const profile =
    walletPublicKey == null ? selectMyCollectorsProfile(state) : selectCollectorsProfile(state, walletPublicKey);

  const relatedEvents = profile?.collectedExhibitions;

  return {
    randomCreator,
    isCreatorsLoading,
    selectedWalletType: activeWalletType,
    connectedWallets: selectConnectedWalletTypes(state).map((wallet) => ({
      ...getWalletDetails(wallet, appConfig.appTitle),
      type: wallet,
      walletBalance: (selectWalletBalance(state, wallet) || 0).toString(),
      nftsCount: 1, // FIXME
      publicKey: selectWalletAddress(state, activeWalletType),
    })),
    profileNfts: loading ? undefined : profileNfts,
    walletPublicKey,
    relatedEvents,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  loadCreators: () => dispatch(FetchCreatorsCommand.create()),
  fetchProfileNfts: (walletPublicKey: string) => dispatch(FetchProfileCommand.create(walletPublicKey)),
  disconnectWallet: (type: SupportedWalletType) => dispatch(DisconnectWalletCommand.create(type)),
  openWallet: (type: SupportedWalletType) => dispatch(OpenWallet.create(type)),
  loadNfts: (nftIds: string[]) => dispatch(LoadNftsArrayByIdsCommand.create(nftIds)),
});

export const WalletPage = connect(mapStateToProps, mapDispatchToProps)(WalletPageView);
