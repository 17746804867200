import {BigNumber, BigNumberish} from 'ethers';

import {TOKEN_DECIMALS_POW} from '../../config/common';
import {toBigNumber, toNumber} from './big-number-utils';

export const TOKEN_DECIMALS = BigNumber.from(10).pow(TOKEN_DECIMALS_POW);

type FormatPriceParams = {
  currency?: string;
  prettify?: boolean;
};

export const cereToUSDCents = (price: BigNumberish, unitsPerPenny: BigNumberish = 1): number => {
  const ratio = toBigNumber(unitsPerPenny).eq(0) ? 1 : unitsPerPenny;
  return toNumber(toBigNumber(price).div(toBigNumber(ratio)));
};
// Cere units to USD
export const centToUSD = (price: BigNumberish): number => Number(toNumber(toBigNumber(price).div(100)).toFixed(2));
export const formatPrice = (price: BigNumberish, {currency = '$', prettify = true}: FormatPriceParams = {}): string => {
  return `${currency}${prettify ? toNumber(price).toFixed(2) : toNumber(price)}`;
};

export const decimalsPriceToUsd = (price: number): number => {
  return Math.round(toBigNumber(price).div(TOKEN_DECIMALS).mul(100).toNumber()) / 100;
};

export const usdWithCentsToDecimals = (usdValue: number, decimals: number): BigNumber => {
  const centsValue = BigNumber.from(Math.round(usdValue * 100));
  return centsValue.mul(BigNumber.from(10).pow(decimals)).div(100);
};
