import {NFT} from '@cere/media-sdk-client';
import {NftContentView, useNftMetadata} from '@cere/media-sdk-react';
import {CircularProgress} from '@material-ui/core';
import React from 'react';

import {ReactComponent as DownloadIcon} from '../../../../assets/svg/download.svg';
import {SliderContent} from '../../../../types/common';
import {Loader} from './loader';
import {useStyles} from './styles';

type NftAssetProps = {
  content: SliderContent;
  nftPage: boolean;
  className?: string;
  onLoadImage?: () => void;
  userWalletAddress?: string | null;
};

export const NftAsset: React.FC<NftAssetProps> = ({content, nftPage, onLoadImage}) => {
  const styles = useStyles({eventBackgroundUrl: content.asset.preview});

  const {collectionAddress, nftId, currentIndex} = content;
  const {metadata, isLoading, error} = useNftMetadata(collectionAddress!, Number(nftId));

  if (isLoading || !metadata || !!error) {
    return <CircularProgress size="30px" />;
  }

  const nft = {
    collection: {address: collectionAddress},
    nftId: Number(nftId),
  } as unknown as NFT;

  return (
    <NftContentView
      nft={nft}
      metadata={metadata}
      assetIndex={currentIndex}
      loadingComponent={<Loader />}
      callback={onLoadImage}
      classNames={{
        container: styles.videoContainer,
        imageBlock: styles.imageBlock,
        image: styles.image,
        content: styles.content,
        downloadContent: styles.downloadContent,
      }}
      DownloadIcon={nftPage ? DownloadIcon : undefined}
    />
  );
};
