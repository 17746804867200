import {Box, makeStyles, Typography} from '@material-ui/core';
import clsx from 'clsx';
import {memo, ReactNode} from 'react';

import {CmsExhibit} from '../../../types/exhibit';
import {UsersNftCardInterface} from '../../../types/nft';
import {QrCodeTicketGenerateButton} from '../../connected/QRCodeTicketGenerateButton/QrCodeTicketGenerateButtons';
import {CardImage} from '../../primitives/CardImage';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '12px',
    overflow: 'hidden',
    minWidth: '100px',
    border: `1px solid ${theme.palette.grey[200]}`,
  },
  infoBox: {
    padding: '16px 8px',
    backgroundColor: theme.palette.primary.light,
    [theme.breakpoints.up('lg')]: {
      padding: '8px',
    },
  },
  title: {
    paddingBottom: '4px',
  },
  priceElement: {
    fontSize: '16px',
    lineHeight: '22px',
    paddingTop: '6px',
  },
  overflowText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

type Props = {
  nft: UsersNftCardInterface;
  title: string;
  description: NonNullable<ReactNode>;
  priceElement: NonNullable<ReactNode>;
  event?: CmsExhibit;
  walletPublicKey?: string;
};

export const CardSmall = memo(({nft, title, description, priceElement, event, walletPublicKey}: Props) => {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <CardImage nftId={nft.id} />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" flexDirection="column" className={styles.infoBox}>
          <Typography variant="caption" style={{fontWeight: 500}} className={styles.title} noWrap>
            {title}
          </Typography>
          <Box>{description}</Box>

          <Typography className={clsx(styles.priceElement, styles.overflowText)}>{priceElement}</Typography>
        </Box>
        {event?.id && walletPublicKey && event.eventType === 'in_person' && (
          <QrCodeTicketGenerateButton
            userWalletAddress={walletPublicKey}
            userNfts={[nft]}
            event={event}
          ></QrCodeTicketGenerateButton>
        )}
      </Box>
    </Box>
  );
});

CardSmall.displayName = 'CardSmall';
