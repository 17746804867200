import axios, {AxiosInstance} from 'axios';
import {Signer} from 'ethers';
import {getAddress} from 'ethers/lib/utils';

import {FREEPORT_API_URL} from '../../config/common';
import {AuthHeaderKeys, GetAuthMessageResponse} from './types';

/**
 * Interacts with the DDC Content Service Module in the Freeport DDC Proxy Service
 * to facilitate the creation of buckets, upload an retrieval of content to the DDC
 */
export class DdcContentService {
  /**
   * Auth token gets stored when the authentication function has been run against this singleton instance
   */
  private static authHeaders?: Record<AuthHeaderKeys, string>;

  static isAuthenticated(): boolean {
    return !!this.authHeaders;
  }

  static disconnect(): void {
    this.authHeaders = undefined;
  }

  /**
   * Authenticate with the DDC Content Service
   * @param signer The signer to use to sign the authentication message
   */
  static async authenticate(signer: Signer): Promise<void> {
    if (this.isAuthenticated()) return;

    const address = await signer.getAddress();
    const {data: message} = await this.authApi().get<GetAuthMessageResponse>(
      `/api/wallet-auth/auth-message?walletPublicKey=${address}`,
    );

    const signature = await signer.signMessage(message);

    this.authHeaders = {
      'x-message': message,
      'x-signature': signature,
      'x-public-key': getAddress(address),
    };
  }

  /**
   * Get the stream key for an encrypted server side video stream
   * @param collectionAddress The address of the collection to check
   * @param nftId The ID of the NFT to check
   * @param bucketId The ID of the bucket to check
   * @param cid The CID of the content to check
   * @returns The encrypted stream key for the given asset
   */
  static async getStreamKey({
    collectionAddress,
    nftId,
    bucketId,
    cid,
  }: {
    collectionAddress: string;
    nftId: number;
    bucketId: number;
    cid: string;
  }): Promise<string> {
    const {data} = await this.contentApi().get(
      `/api/video/streaming/${collectionAddress}/${nftId}/${bucketId}/${cid}/stream-key`,
    );

    return data;
  }

  /**
   * Check if the caller has access to the content associated with this token
   * @returns True if the caller has access to the content, false otherwise
   */
  static async getHasAccess({
    collectionAddress,
    nftId,
    signer,
  }: {
    collectionAddress: string;
    nftId: number;
    signer: Signer;
  }): Promise<boolean> {
    await this.authenticate(signer);
    const {data} = await this.contentApi().get(
      `/api/content/${collectionAddress}/${nftId}/${await signer.getAddress()}/access`,
    );

    return data;
  }

  private static authApi(): AxiosInstance {
    return axios.create({
      baseURL: FREEPORT_API_URL(),
    });
  }

  private static contentApi(): AxiosInstance {
    return axios.create({
      baseURL: FREEPORT_API_URL(),
      headers: this.authHeaders,
    });
  }
}
