const cropHexAddress = (address: string, startSymbols = 7, endSymbols = 3): string => {
  const start = address.substring(0, startSymbols);
  const end = address.slice(-1 * endSymbols);

  return `${start}...${end}`;
};

export const attachFileForDownload = (url: string, name = 'cere'): void => {
  const link = document.createElement('a');
  link.href = url;
  link.download = name;
  document.body.append(link);
  link.click();
  document.body.removeChild(link);
};

export {cropHexAddress};
