import {NftCardInterface, NftTransactionInterface, PaymentStatusEnum} from '@cere/services-types';

export const isHasSuccessTransactions = (nft: NftCardInterface): boolean => {
  const transactions: NftTransactionInterface[] = nft?.transactions || [];

  return (
    transactions.filter((t) => t.paymentStatus && [PaymentStatusEnum.PAYMENT_SUCCESS].includes(t.paymentStatus))
      .length > 0
  );
};

export const isNftPending = (nft: NftCardInterface): boolean => {
  const transactions: NftTransactionInterface[] = nft?.transactions || [];

  const hasPendingTransactions =
    transactions.filter(
      (t) =>
        t.paymentStatus &&
        [
          PaymentStatusEnum.FIAT_PAYMENT_PENDING,
          PaymentStatusEnum.FIAT_PAYMENT_SUCCESS,
          PaymentStatusEnum.TOKEN_TRANSFER_START,
          PaymentStatusEnum.TOKEN_TRANSFER_PENDING,
          PaymentStatusEnum.TOKEN_TRANSFER_SUCCESS,
        ].includes(t.paymentStatus),
    ).length > 0;
  const hasSuccessTransactions =
    transactions.filter((t) => t.paymentStatus && [PaymentStatusEnum.PAYMENT_SUCCESS].includes(t.paymentStatus))
      .length > 0;

  return hasSuccessTransactions ? false : hasPendingTransactions;
};
